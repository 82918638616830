<template>
    <div v-if="loading" class="w-1/4 pt-32 mx-auto text-center">
        <div class="flex justify-center svg-wrapper">
            <spinning-clock />
        </div>
        <h2 class="mt-8 text-brand-500">{{ messages[currentMessage] }}</h2>
    </div>
    <div v-else>
        <div class="relative flex flex-wrap mt-2">
            <a v-if="data_id" class="btn-transparent flex flex__align-center ml-auto mb-4" :href="`/shared/price-leadtime/${data_id}`" target="_blank">
                <svg-importer icon-name="icons/share" class="mr-2" />
                <span class="text-body">
                    Generate a public link
                </span>
            </a>

            <card
                title="Result"
                class="w-full"
                bodyClass="pt-0 pl-0 pr-0 pb-0 overflow-visible"
            >
                <template slot="tools">
                    <div class="flex">
                        <button
                            v-for="(transport, index) in transportModes"
                            :key="'transport' + index"
                            @click="toggleFilter(transport)"
                            class="capitalize pointer btn-pill flex"
                            :class="{'active' : activeFilters.includes(transport)}"
                        >
                            <svg-importer
                                :icon-name="`icons/${getTransportModeIcon(transport)}`"
                                class="mr-1 "
                            />
                            {{transport}}
                        </button>
                    </div>
                </template>
                <v-table
                    :columns="columns"
                    row-key="id"
                    ref="bookings-result"
                    :id="'priceLeadTimeResult'"
                    :rows="filteredResults"
                    sort-key="total_cost"
                    :sort-order="1"
                    :query="this.params"
                    @row-click="selectShipment"
                    has-detail
                    :custom-export="myExport"
                >
                    <template slot="tools">
                        <div>
                            <multiselect
                                v-model="currentCurrency"
                                :options="currencyOptions"
                                placeholder="Select currency"
                            />
                        </div>
                    </template>
                    <template slot="cell" slot-scope="{row, col}">
                        <div class="flex" v-if="col.key == 'select'">
                            <input type="radio" v-model="priceLeadTimeResult" :value="row" style="height: 32px;">
                        </div>
                        <span v-else-if="col.key == 'total_cost'">
                            {{ getTotalPrice(row) }}
                        </span>
                        <div v-else-if="col.key == 'transport_mode'" class="pl-4">
                            <svg-importer
                                :icon-name="`icons/${getTransportModeIcon(row.transport_mode)}`"
                            />
                        </div>
                        <span v-else-if="col.key == 'time_of_delivery'">
                            <span v-if="getDeliveryDate(row)">{{ getDeliveryDate(row) }}</span>
                            <span v-else class="text-sm italic text-gray-600">Not specified</span>
                        </span>
                        <span
                            v-else-if="col.key == 'recommended'"
                            class="pl-2"
                        >
                            <span v-if="recommendLowestPrice(row) && bookingsActive">
                                <span class="ml-1">
                                    Recommended
                                </span>
                            </span>
                        </span>
                        <span v-else-if="col.key === 'service'">
                            {{ row.carrier_service_model.name }}
                        </span>
                        <div v-else-if="col.key === 'service_description'">
                            {{ getServiceDescription(row) }}
                        </div>

                        <div
                            v-else-if="col.key === 'owner_tags'"
                            :title="getTags(row[col.key])"
                            :class="col.width"
                            class="truncate"
                        >
                            {{ getTags(row[col.key]) }}
                        </div>

                        <div
                            v-else-if="col.key === 'co_km_kg'"
                            :title="row[col.key] || ''"
                            :class="col.width"
                            class="truncate"
                        >
                            <span v-if="row[col.key]">
                                {{ row[col.key].toFixed(2) }}
                            </span>
                            <span v-else class="text-sm italic text-gray-600">No data</span>
                        </div>

                        <span v-else>{{ row[col.key] || '-' }}</span>
                    </template>

                    <template slot="detail" slot-scope="{ row }">
                        <div
                            v-if="row.service_provider !== 'proxio'"
                            class="flex flex-col flex-wrap content-start w-full h-56 pt-4 ml-6 xxl:h-40 xl:h-48"
                        >
                            <div
                                v-for="(detail, index) in row.details"
                                class="self-start mb-4"
                                :key="index"
                            >
                                <modal-field :label="detail.description">
                                    <span class="text-base">
                                        {{ formatThousands(detail.quantity,2) +' '+ detail.unit }}
                                    </span>
                                </modal-field>
                            </div>
                        </div>
                        <div v-if="row.service_provider === 'proxio'" class="w-full">
                            <!-- calculation -->
                            <div class="w-full mt-4">
                                <p class="text-base font-semibold">Calculation basis (Cost drivers relevant for the carrier service)</p>
                                <div class="flex flex-wrap w-full">
                                    <div class="w-1/6 pr-4 mt-4">
                                        <modal-field label="Distance" body-class="mt-2">
                                            <span class="text-base" v-if="getCalculationBasisText(row.calculation_basises, 'Distance')">
                                                {{ getCalculationBasisText(row.calculation_basises, 'Distance') }}
                                            </span>
                                            <span v-else class="italic text-gray-600">Not specified</span>
                                        </modal-field>
                                    </div>

                                    <div class="w-1/6 pr-4 mt-4">
                                        <modal-field label="Volume" body-class="mt-2">
                                            <span class="text-base" v-if="getCalculationBasisText(row.calculation_basises, 'Volume')">
                                                {{ getCalculationBasisText(row.calculation_basises, 'Volume') }}
                                            </span>
                                            <span v-else class="italic text-gray-600">Not specified</span>
                                        </modal-field>
                                    </div>

                                    <div class="w-1/6 pr-4 mt-4">
                                        <modal-field label="Weight" body-class="mt-2">
                                            <span class="text-base" v-if="getCalculationBasisText(row.calculation_basises, 'Weight')">
                                                {{ getCalculationBasisText(row.calculation_basises, 'Weight') }}
                                            </span>
                                            <span v-else class="italic text-gray-600">Not specified</span>
                                        </modal-field>
                                    </div>
                                    <div class="w-1/6 pr-4 mt-4">
                                        <modal-field label="Loading meters" body-class="mt-2">
                                            <span class="text-base" v-if="getCalculationBasisText(row.calculation_basises, 'LoadingMeters')">
                                                {{ getCalculationBasisText(row.calculation_basises, 'LoadingMeters') }}
                                            </span>
                                            <span v-else class="italic text-gray-600">Not specified</span>
                                        </modal-field>
                                    </div>

                                    <div class="w-1/6 pr-4 mt-4">
                                        <modal-field label="Pallet spaces" body-class="mt-2">
                                            <span class="text-base" v-if="getCalculationBasisText(row.calculation_basises, 'PalletSpaces')">
                                                {{ getCalculationBasisText(row.calculation_basises, 'PalletSpaces') }}
                                            </span>
                                            <span v-else class="italic text-gray-600">Not specified</span>
                                        </modal-field>
                                    </div>
                                    <div class="w-1/6 pr-4 mt-4">
                                        <modal-field label="Calculated weight" body-class="mt-2">
                                            <span class="text-base" v-if="getCalculationBasisText(row.calculation_basises, 'CalculatedWeight')">
                                                {{ getCalculationBasisText(row.calculation_basises, 'CalculatedWeight') }}
                                            </span>
                                            <span v-else class="italic text-gray-600">Not specified</span>
                                        </modal-field>
                                    </div>
                                </div>
                            </div>
                            <!-- price-details -->
                            <div class="w-full my-6">
                                <p class="text-base font-semibold">Calculation details</p>

                                <div class="flex flex-wrap w-full">
                                    <div v-for="(detail, index) in row.details" class="w-1/4 pr-4 mt-4 xxxl:w-1/6" :key="index">
                                        <modal-field :label="detail.description" body-class="mt-2">
                                            <span class="text-base">
                                                {{ formatThousands(detail.price,2) +' '+ detail.unit }}
                                            </span>
                                        </modal-field>
                                    </div>
                                </div>

                                <div v-if="row.calculation_source" class="w-1/4 mt-4">
                                    <modal-field label="Leadtime calculations">
                                        <span v-text="row.calculation_source" class="text-base" />
                                    </modal-field>
                                </div>
                            </div>
                        </div>
                    </template>
                </v-table>
            </card>
        </div>

        <div v-if="allErrors.length" class="p-4 mt-6 bg-red-300">
            <ul>
                <div v-for="(error, index) in allErrors" :key="`error_${index}`">
                    <li>{{ error }}</li>
                </div>
            </ul>
        </div>
        
    </div>
</template>

<script>
    import moment from "moment";
    import axios from "axios";
    import mapboxgl from "mapbox-gl";
    import * as turf from "@turf/turf";
    import zipcelx from "zipcelx";
    import Multiselect from 'vue-multiselect';

    import { requestErrors } from '~/utils/errors';

    import AdditionalServicesMixin from '~/mixins/addServices.mixin.js';

export default {

    components: { Multiselect },
    mixins: [AdditionalServicesMixin],

    props: {
        sharedData: {}
    },
    data: () => ({
        data_id: null,
        currentCurrency: '',
        currencyOptions: [],
        loading: true,
        proxioErrors: [],
        geocoding: null,
        currentMessage: 0,
        params: null,
        pointTypes: ["from", "to"],
        result: [],
        priceLeadTimeResult: null,
        activeFilters: [
            'road', 'sea', 'air', 'courier', 'rail', 'express'
        ],
        transportModes: [
            'road', 'sea', 'air', 'courier', 'rail', 'express'
        ],
        messages: [
            "This will make the Kessel run in under 12 parsecs!",
            "Hamster at almost full speed",
            "Crunching the numbers...",
            "Doing the maths...",
            "*quick snack break*",
            "Getting impatient...",
            "This is gonna be so awesome!",
            "Whoa, almost there!",
            "The two most powerful warriors are patience and time.",
            "*doing the locomotion*",
            "Patience my young padawan...",
            "We're not lost... yet."
        ],
        columns: [
            {
                title: "",
                key: "select",
                toggled: true,
                width: "w-4",
                sortable: false
            },
            {
                title: "Mode",
                key: "transport_mode",
                toggled: true,
                width: "w-16",
                sortable: true
            },
            {
                title: "Carrier",
                key: "carrier",
                toggled: true,
                width: "w-24",
                sortable: true
            },
            {
                title: "Service",
                key: "service",
                toggled: true,
                width: "w-36",
                sortable: true
            },
            {
                title: "Agreement owner",
                key: "owner_tags",
                toggled: true,
                width: "w-36",
                sortable: true
            },
            {
                title: "Service description",
                key: "service_description",
                toggled: true,
                width: "w-36",
                sortable: true
            },
            {
                title: "Price",
                key: "total_cost",
                toggled: true,
                width: "w-24",
                alignRight: false,
                sortable: true
            },
            {
                title: "av. CO2e normalized",
                key: "co_km_kg",
                toggled: true,
                width: "w-24",
                alignRight: false,
                sortable: true
            },
            {
                title: "Delivery date",
                key: "time_of_delivery",
                toggled: true,
                width: "w-24",
                sortable: true
            },
            {
                title: "",
                key: "recommended",
                toggled: true,
                width: "w-12",
                alignRight: false,
            }
        ]
    }),

    computed: {
        bookingsActive() {
            return this.$store.getters["price-leadtime/bookingModule"];
        },
        filteredResults() {
            const resultsWithTransportMode = this.result.filter(item => this.activeFilters.includes(item.transport_mode ? item.transport_mode.toLocaleLowerCase() : item.transport_mode));
            const resultsWithNoTransportMode = this.result.filter(item => item.transport_mode == null);
            return resultsWithTransportMode.concat(resultsWithNoTransportMode) ;
        },
        user() {
            return this.$store.getters["auth/user"];
        },
        currentStep() {
            return this.$store.getters["price-leadtime/currentStep"];
        },
        errors() {
            return this.$store.getters['price-leadtime/activeSteps'][this.currentStep].errors
        },
        allErrors() {
            const errors = [];
            const self = this;

            Object.keys(this.errors).forEach(function(errorArray) {
                self.errors[errorArray].forEach((error) => {
                    errors.push(error)
                })
            });

            return [...errors, ...this.proxioErrors]
        },
        isShared() {
            return this.$route.name === 'shared.priceleadtime.detail';
        },
    },

    created() {
        this.selectShipment({ data: null });
        this.fetchData();
        this.rotateMessages();

        this.currentMessage = Math.floor(Math.random() * this.messages.length);
        this.setData();
    },

    methods: {
        getTags(tags) {
            return tags?.join(', ') || '-';
        },
        getServiceDescription(row) {
            return row.carrier_service_model.service_description?.service_description || '-';
        },
        makeLayers() {

            if (! this.geocoding) {
                return [];
            }

            let source = this.makeSource();

            return [
                {
                    id: "line",
                    type: "line",
                    source: source,
                    paint: {
                        "line-color": this.$cookies.get('brand_color') || '#2E457C',
                        "line-width": 4
                    },
                    filter: ["==", "$type", "LineString"]
                }
            ];
        },
        makeSource() {
            let start = turf.point([
                this.geocoding.from.lon,
                this.geocoding.from.lat
            ]);
            let end = turf.point([
                this.geocoding.to.lon,
                this.geocoding.to.lat
            ]);

            let greatCircle = turf.greatCircle(start, end, { name: "hepp" });

            return {
                type: "geojson",
                data: {
                    type: "FeatureCollection",
                    features: [start, end, greatCircle]
                }
            };
        },
        makeMarkers() {
            if (! this.geocoding) {
                return [];
            }

            return this.pointTypes.map(type => {
                let el = document.createElement("div");
                let className = "marker marker-" + type;

                className += " marker-skew";
                el.innerHTML = `<div class="${className}"></div>`;

                return {
                    el: el,
                    lon: this.geocoding[type].lon,
                    lat: this.geocoding[type].lat
                };
            });
        },
        makeBounds() {
            if (! this.geocoding) {
                return null;
            }

            let source = this.makeSource();
            var bounds = new mapboxgl.LngLatBounds();

            source.data.features.forEach(function(feature) {
                bounds.extend(feature.geometry.coordinates);
            });

            return {
                bounds,
                options: {
                    //bearing: bearing,
                    padding: {
                        top: 0,
                        bottom: 50,
                        left: 50,
                        right: 50
                    }
                }
            };
        },
        recommendLowestPrice(row) {
            var isLowest = true;
            this.result.forEach(result => {
                if(result.total_cost < row.total_cost) {
                    isLowest = false;
                }
            });

            if(isLowest) {
                return true;
            }
        },
        async selectShipment(row) {
            if (row.data !== null) {
                row.data.carrier_model = { id: row.data.carrier_service_model.carrier_id };
            }

            this.priceLeadTimeResult = row.data;

            await this.$store.commit('price-leadtime/UPDATE_PRICE_LEAD_TIME_RESULT', row.data);

            if (row.data !== null) {
                await this.fetchAddServices();
            }
        },
        setData() {
            this.priceLeadTimeResult = this.$store.getters[`price-leadtime/result`];
        },
        toggleFilter(item) {
            if(this.activeFilters.includes(item)) {
                var filters = this.activeFilters.filter(filter => filter != item);
                this.activeFilters = filters;
            } else {
                this.activeFilters.push(item);
            }
        },
        myExport(table){
            let filename = 'price_leadtime' + new Date().toLocaleDateString();
            let exportRows = []
            let exportRow = []

            table.toggledColumns.forEach(column => {
                exportRow.push({
                    value: column.title,
                    type: 'string'
                })
            });
            exportRows.push(exportRow);

            table.rows.forEach(tableRow => {
                let exportRow = [];

                table.toggledColumns.forEach(column => {
                    let cell = tableRow[column.key];
                    let type = 'string';

                    if(column.key == 'total_cost') {
                        type = 'number'
                    }
                    exportRow.push({
                        value: cell,
                        type: type
                    })
                })
                exportRows.push(exportRow)
            });

            const config = {
                filename: filename,
                sheet: {
                    data: exportRows
                }
            }
            zipcelx(config);
        },
        getTotalPrice(row){
            let totc = row.details.find(item => item.item_id == 'TOTC');

            if(totc) {
                return this.formatThousands(totc.quantity,2) + ' ' + totc.unit
            }

            if (this.currentCurrency) {
                return `${this.formatThousands(row.total_cost_variants[this.currentCurrency], 2)} ${this.currentCurrency}`;
            }

            return `${this.formatThousands(row.total_transport_cost,2)} ${row.details[0].unit}`;
        },
        getDeliveryDate(item) {
            if(item.time_of_delivery != null) {
                const date = moment(item.time_of_delivery);

                if (!date.hours() && !date.minutes() && !date.seconds()) {
                    return date.format('YYYY-MM-DD');
                }

                if (!date.seconds()) {
                    return date.format('YYYY-MM-DD HH:mm');
                }

                return item.time_of_delivery;
            } else {
                return false;
            }
        },
        getCalculationBasisText(data, type){
            if(type === 'CalculatedWeight'){
                for(let i=0;i<data.length;i++){
                    if(data[i].type === 'Weight'){
                        return data[i].value + ' ' + data[i].unit + ' (based on ' + data[i].source.toLowerCase() + ')';
                    }
                }
            }else{
                for(let i=0;i<data.length;i++){
                    if(data[i].type === type){
                        return data[i].value + ' ' + data[i].unit;
                    }
                }
            }

            return false;
        },
        rotateMessages() {
            setTimeout(() => {
                if (this.messages.length > this.currentMessage + 1) {
                    this.currentMessage++;
                } else {
                    this.currentMessage = 0;
                }

                if (this.loading) {
                    this.rotateMessages();
                }
            }, 3000);
        },

        setCurrencyOptions() {
            const currencyKeysSet = new Set();

            this.result.forEach(item => {
                const keys = Object.keys(item.total_cost_variants);
                if (!keys?.length) {
                    return;
                }

                keys.forEach(key => {
                    currencyKeysSet.add(key);
                })
            });

            this.currencyOptions = Array.from(currencyKeysSet);
        },

        async fetchSimple() {
            this.$emit('proxio-loading-start');
            
            try {
                const all = this.$store.getters['price-leadtime/all'];
                const goodsKeys = ['units', 'load_meters', 'pallet_spaces', 'weight_line', 'volume_line'];
                const form = new FormData();

                const data = {
                    fromCountryCode: all.address.pickup.country.value,
                    fromZipCode: all.address.pickup.zip,
                    fromCity: all.address.pickup.city,
                    toCountryCode: all.address.delivery.country.value,
                    toZipCode: all.address.delivery.zip,
                    toCity: all.address.delivery.city,
                    transportDate: moment(all.address.pickup.pickup_date).format('YYYY-MM-DD'),
                    pickupTimeEarliest: this.getPickupTime(all.address.pickup.pickupTimeEarliest.value),
                    pickupTimeLatest: this.getPickupTime(all.address.pickup.pickupTimeLatest.value),
                };

                all.goods.items.forEach((item, i) => {
                    for (let key in item) {
                        const findKey = goodsKeys.find(_key => { return key === _key });

                        if (!findKey) {
                            continue;
                        }

                        if (!item[key]) {
                            continue;
                        }

                        if (key === 'load_meters') {
                            data[`goods[${i}][loadingMeter]`] = item[key];

                            continue;
                        }

                        if (key === 'pallet_spaces') {
                            data[`goods[${i}][palletSpaces]`] = item[key];

                            continue;
                        }

                        if (key === 'weight_line') {
                            data[`goods[${i}][weight]`] = Number(item.weight_line);

                            continue;
                        }

                        if (key === 'volume_line') {
                            data[`goods[${i}][volume]`] = Number(item.volume_line);

                            continue;
                        }

                        if (['weight', 'height', 'width', 'depth', 'length'].includes(key)) {
                            continue;
                        }

                        data[`goods[${i}][${key}]`] = item[key];
                    }
                })

                for (let key in data) {
                    form.append(key, data[key]);
                }

                const result = await axios.post(this.$apiUrl.proxio.calculationSimple, form, { headers: { "Content-Type": "multipart/form-data" }, })

                this.result = result.data.data.result;
                this.data_id = result.data.data.data_id;

                this.loading = false;

                if (!this.result?.length) {
                    this.$emit('start-over');
                }
            } catch(error) {
                this.$snotify.error(requestErrors(error) || 'Error simple response');

                this.$store.commit('price-leadtime/SET_CURRENT_STEP', this.currentStep - 1);
            }
            
            this.$emit('proxio-loading-end');
        },
        async fetchSmart() {
            try {
                const all = this.$store.getters['price-leadtime/all'];
                const goodsKeys = ['units', 'weight', 'loadingMeter', 'volume', 'palletSpaces', 'width', 'height', 'depth'];
                const form = new FormData();

                const data = {};

                for (let key in all.address) {
                    if (!all.address[key].active) {
                        continue
                    }

                    const id = key === 'pickup' ? 'sender' : 'receiver'

                    data[`${id}[country]`] = all.address[key].country.value;
                    data[`${id}[zip]`] = all.address[key].zip;
                    data[`${id}[city]`] = all.address[key].city;
                }

                all.goods.items.forEach((item, i) => {
                    for (let key in item) {
                        const findKey = goodsKeys.find(_key => { return key === _key });

                        if (!findKey) {
                            continue;
                        }

                        if (!item[key]) {
                            continue;
                        }

                        if (key === 'load_meters') {
                            data[`goods[${i}][loadingMeter]`] = item[key];

                            continue;
                        }

                        if (key === 'pallet_spaces') {
                            data[`goods[${i}][palletSpaces]`] = item[key];

                            continue;
                        }

                        data[`goods[${i}][${key}]`] = item[key];
                    }
                })

                for (let key in data) {
                    form.append(key, data[key]);
                }

                const result = await axios.post(this.$apiUrl.proxio.calculationSmart, form, { headers: { "Content-Type": "multipart/form-data" }, })

                this.result = result.data.data.result;
                this.data_id = result.data.data.data_id;

                this.loading = false;
            } catch(error) {
                this.$snotify.error(error?.response?.message || 'Error smart response');

                this.$store.commit('price-leadtime/SET_CURRENT_STEP', this.currentStep - 1);
            }
        },

        async fetchData() {
            if (this.isShared) {
                this.result = this.sharedData;

                this.loading = false;
                this.columns.splice(this.columns.findIndex(column => column.key === 'select'), 1);
                
                this.setCurrencyOptions();

                return;
            }

            await this.fetchSimple();

            this.setCurrencyOptions();
        },
    },

}
</script>

<style>

</style>
